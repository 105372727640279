import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import menuIntelImg from "../../assets/images/global-splash-mi-card.png";
import conceptLockerImg from "../../assets/images/global-splash-cl-card.png";
import marketLaunchImg from "../../assets/images/global-splash-ml-card.png";
import mintelImg from "../../assets/images/global-splash-mintel-card.png";
import { NavLink } from "react-router-dom";

export default function GlobalSplashNavigation() {
  let CONCEPT_LOCKER_HOME_LINK = "https://conceptlocker.miron.services";
  let MARKET_LAUNCH_HOME_LINK = "https://marketlaunch.miron.services";
  if (window.location.hostname === "flavoriq.global") {
    CONCEPT_LOCKER_HOME_LINK = "https://conceptlocker.flavoriq.global";
    MARKET_LAUNCH_HOME_LINK = "https://marketlaunch.flavoriq.global";
  }

  return (
    <>
      <div className="global-splash-cards-wrapper">
        <div className="splash-cards">
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            style={{ flexBasis: "100%", marginLeft: "25px" }}
          >
            <em>GRIFFITH FOODS INNOVATIONS</em>
          </Typography>
          <NavLink
            to={CONCEPT_LOCKER_HOME_LINK}
            style={{ textDecoration: "none" }}
          >
            <Card sx={{ mb: 3, mt: 1 }} className="splash-card">
              <CardActionArea className="splash-card--action-area">
                <CardMedia
                  component="img"
                  image={conceptLockerImg}
                  alt="browse"
                  sx={{
                    ml: "auto",
                    mr: "auto",
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="div"
                    style={{ color: "#ff9300", fontSize: "20px" }}
                  >
                    <em>Culinary Concept Locker</em>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Griffith Foods culinary staff turn ingredients into culinary
                    inspiration, based on real-life insights.
                  </Typography>
                  <KeyboardArrowDownIcon
                    color="primary"
                    aria-label="arrow"
                    sx={{
                      mt: 1,
                      mb: 1,
                      fontSize: "2.4rem",
                      fill: "#ff9300",
                      "&:hover": { fill: "#8dc63f" },
                    }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </NavLink>

          <NavLink
            to={MARKET_LAUNCH_HOME_LINK}
            style={{ textDecoration: "none" }}
          >
            <Card sx={{ mb: 3, mt: 1 }} className="splash-card">
              <CardActionArea className="splash-card--action-area">
                <CardMedia
                  component="img"
                  image={marketLaunchImg}
                  alt="browse"
                  sx={{
                    ml: "auto",
                    mr: "auto",
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="div"
                    style={{ color: "#930a93", fontSize: "20px" }}
                  >
                    <em>Market Launch</em>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Explore Griffith Foods innovations that have made it into
                    products, and onto menus, and store shelves around the
                    globe.
                  </Typography>
                  <KeyboardArrowDownIcon
                    htmlColor="#930a93"
                    aria-label="arrow"
                    sx={{
                      mt: 1,
                      mb: 1,
                      fontSize: "2.4rem",
                      "&:hover": { fill: "#8dc63f" },
                    }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </NavLink>
        </div>

        <div className="splash-cards">
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            style={{ flexBasis: "100%", marginLeft: "25px" }}
          >
            <em>INDUSTRY INNOVATIONS</em>
          </Typography>

          <NavLink to="/dashboard" style={{ textDecoration: "none" }}>
            <Card sx={{ mb: 3, mt: 1 }} className="splash-card">
              <CardActionArea className="splash-card--action-area">
                <CardMedia
                  component="img"
                  image={menuIntelImg}
                  alt="browse"
                  sx={{
                    ml: "auto",
                    mr: "auto",
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="div"
                    style={{ color: "#007044", fontSize: "20px" }}
                  >
                    <em>Menu Intelligence</em>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    See what's trending on menus with chain restaurant and
                    foodservice customers in your market and around the world.
                  </Typography>
                  <KeyboardArrowDownIcon
                    color="primary"
                    aria-label="arrow"
                    sx={{
                      mt: 1,
                      mb: 1,
                      fontSize: "2.4rem",
                      "&:hover": { fill: "#8dc63f" },
                    }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </NavLink>

          <NavLink
            to="https://portal.mintel.com/portal/login?next=/%3F_gl%3D1%2A1k6vvg7%2A_up%2AMQ..%2A_gs%2AMQ..%26gclid%3DCj0KCQiAs5i8BhDmARIsAGE4xHxKRxXGb0ML0g85fLG9pBhUaxJ-x-OduooHZiNzP9Mt-N9Oqf1QvsAaAmyiEALw_wcB"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Card sx={{ mb: 3, mt: 1 }} className="splash-card">
              <CardActionArea className="splash-card--action-area">
                <CardMedia
                  component="img"
                  image={mintelImg}
                  alt="browse"
                  sx={{
                    ml: "auto",
                    mr: "auto",
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="div"
                    style={{ color: "#00aaf0", fontSize: "20px" }}
                  >
                    <em>Mintel Insights</em>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Mintel's Global New Products Database provides market
                    intelligence across Retail, Protein, and Food Manufacturer
                    industries to help guide marketing strategies and business
                    decisions.
                  </Typography>
                  <KeyboardArrowDownIcon
                    color="primary"
                    aria-label="arrow"
                    sx={{
                      mt: 1,
                      mb: 1,
                      fontSize: "2.4rem",
                      fill: "#00aaf0",
                      "&:hover": { fill: "#8dc63f" },
                    }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </NavLink>
        </div>
      </div>
    </>
  );
}
