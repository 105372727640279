import React from "react";
import { Typography, Grid } from "@mui/material";
import SplashSwoosh from "./SplashSwoosh";
import styled from "@emotion/styled";
import logo from "../../assets/images/griffith-logo.png";
import flavorIQLogo from "../../assets/images/flavoriq-logo.png";
import hero from "../../assets/images/global-splash-hero-right.png";
import swoosh from "../../assets/images/global-splash-swoosh.png";

export default function GlobalSplashHero() {
  const Container = styled.div((props) => ({
    background: "#fafafa",
  }));

  return (
    <main>
      <Container maxWidth="xl" disableGutters className="global-hero-wrapper">
        <Grid container>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={6}>
            <div className="hero">
              <div className="global-splash-logo-region">
                <img src={logo} alt="Griffith Foods" className="griffith--logo" />
                <img src={flavorIQLogo} alt="FlavorIQ" className="flavoriq--logo" />
              </div>
              <div className="global-intro-headline">
              <Typography
                variant="h2"
                className="hero--headline"
                sx={{ mt: 5, mb: 1 }}
              >
                <em>
                FlavorIQ<sup>®</sup> Toolkit
                <br/>Innovation Without Limits
                </em>
              </Typography>
              <img src={swoosh} alt="" className="" />
              </div>
             
              <Typography variant="body2" sx={{ mt: 3 }} className="hero--desc">
              The food industry is changing lightning fast and FlavorIQ<sup>®</sup> was created to help you thrive and stay relevant. Our FlavorIQ<sup>®</sup> resources provide access to in-depth, global industry insights and market research – helping you to stay ahead of trends and uncover new opportunities. Together, these tools will help you to create customized products and menu innovations while solving today’s business challenges.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5} sx={{lineHeight: 0}}>
            <img src={hero} alt="hero" className="hero--right" />
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
