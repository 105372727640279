import React from 'react'
import Button from '@mui/material/Button'
import './PrimaryNavTabBar.css'
import { Box } from "@mui/material"
import { NavLink } from 'react-router-dom'
import logo from '../../assets/images/griffith-logo.png';
import pipe from '../../assets/images/header-pipe.png';

export default function PrimaryNavTabBar() {
 
  const styles = {
    navButton: {
      'fontWeight': 500,
      'fontSize': 16,
      'fontStyle': 'italic',
      'textTransform': 'capitalize',
      'borderRadius': '0px', 
      'borderTopLeftRadius': '4px',
      'borderTopRightRadius': '4px',
      'width': '214px',
      'marginRight': '10px',
      'backgroundColor': '#a0c800',
        '&.active': {
          'backgroundColor': '#007044',
        }
      }
    }
  

  return (
    <Box className="header">

      <nav>
      <div className="logo-region">
      <a href="/"><img src={logo} alt="Griffith Foods" className='hero--logo' /></a>
          <img src={pipe}/> 
          <h2><em>Menu Intelligence</em></h2>
        </div>
        
        <div className="nav--grouped">        

          <Button component={NavLink} variant="contained" sx={styles.navButton} to="/browse-menu-items" disableElevation
           >
            Browse Menu Items

          </Button>
          <Button component={NavLink} variant="contained" sx={styles.navButton} to="/presentations" disableElevation
            >
            My Presentations
          </Button>
          <Button component={NavLink} variant="contained" sx={styles.navButton} to="/saved-searches" disableElevation
            >
            Saved Searches
          </Button>
        </div>
        {/*<Button component={NavLink} variant="contained" sx={styles.navButton} to="/admin/manage-menu-filters" className="nav--admin" disableElevation
        >
          Admin Access
  </Button>*/}
      </nav>
    </Box>
  )
}





