import React, { useEffect, useState, useRef } from 'react'
import { useSearchParams } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import algoliasearch from "algoliasearch/lite"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import "./MenuItems.css";
import Container from '@mui/material/Container'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import { useMenu } from 'react-instantsearch-hooks-web';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
    InstantSearch,
    Configure,
    Hits,
    Pagination,
    useClearRefinements,
    useHits,
    useRefinementList
} from "react-instantsearch-hooks-web"
import { format } from 'date-fns'

import { unwrapResult } from '@reduxjs/toolkit'
import { createSavedSearch } from '../store/savedSearchesSlice'
import { createPresentation, updatePresentationMenuItems, addMenuItems, updatePresentationMenuItemOrder } from '../store/presentationsSlice'
import { MenuItemHit } from '../components/algolia'
import { BRANDS_URL_PREFIX, FLAG_URL_PREFIX, SCREENGRAB_URL_PREFIX, MENU_URL_PREFIX } from '../components/shared/Constants'
import { getUser } from '../store/userSlice'
import { usePagination } from 'react-instantsearch-hooks-web';



function CustomPagination(props) {
    const {
        nbHits
    } = usePagination(props);

    return (
        <span>{ nbHits}</span>
        );
}

const searchClient = algoliasearch(
    "MTXS7NWQ7T",
    "651fc21e1d1b8598d6be6271229c57c8"
)
const indexName = "dev.flavoriq.global";
const scrollUpY = 325;

const BrowseMenuItems = () => {   
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [savedSearchLoaded, setSavedSearchLoaded] = React.useState(false);
    const [country, setCountry] = React.useState([]);
    const [countryList, setCountryList] = React.useState(null);
    const [fromDate, setFromDate] = React.useState(new Date(2022, 0, 1));
    const [toDate, setToDate] = React.useState(new Date());
    const [brandList, setBrandList] = React.useState(null);
    const [query, setQuery] = React.useState('');
    const [createPresentationAnchorEl, setCreatePresentationAnchorEl] = React.useState(null);
    const [createPresentationOpen, setCreatePresentationOpen] = React.useState(false);
    const [createSavedSearchOpen, setCreateSavedSearchOpen] = React.useState(false);
    const createPresentationName = React.useRef(null);
    const createSavedSearchName = React.useRef(null);
    const [snackbar, setSnackbar] = React.useState(null);
    const [presentationList, setPresentationList] = React.useState(null);
    const [presentation, setPresentation] = React.useState('');
    const [presentationMenuItems, setPresentationMenuItems] = React.useState([]);
    const filters = React.useRef({});
    const pageIndex = React.useRef(0);
    const user = useSelector(getUser);
    const presentationsRef = React.useRef(null);
    const saveSearchRef = React.useRef(null);
    const [newPresentationRef, setNewPresentationRef] = React.useState(null);
    const [btnDisabled, setBtnDisabled] = useState(true)
    const dispatch = useDispatch();
    const [numberOfItemsSelected, setNumberOfItemsSelected] = React.useState(0);
    const defaultFromDate = React.useState(new Date(2022, 0, 1));
    const defaultToDate = React.useState(new Date());
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setNewPresentationRef(newPresentationRef ? null : event.currentTarget);
        setCreatePresentationOpen(true);
    };
    const [savingSearch, setSavingSearch] = React.useState(false);
    const [savingCreatePresentation, setSavingCreatePresentation] = React.useState(false);

    function loadPresentationMenuItems(selectedPresentation) {
        if (selectedPresentation == 'create') {
            setPresentation('');
            setPresentationMenuItems(null);
        }
        else if (Number.isInteger(selectedPresentation)) {
            var data = {
                id: selectedPresentation,
                menuItems: presentationMenuItems
            };

            dispatch(addMenuItems(data)).then(() => {
                setPresentationMenuItems(null);
                setNumberOfItemsSelected(0);
                setSnackbar({ children: 'Presentation Saved successfully', severity: 'success' });
            }
                
            )
                .catch(rejectedValueOrSerializedError => {
                    console.log(rejectedValueOrSerializedError);
                    setSnackbar({ children: 'Failed to save presentation' });
                    
                });
        }
    }

    function loadPresentations(selectedPresentation) {
        const apiUrl = 'api/presentations/GetUserPresentations/' + user.id
        fetch(apiUrl)
            .then((data) => data.json())
            .then((data) => {
                setPresentationList(data);

                if (selectedPresentation) {
                    setPresentation(selectedPresentation);
                    setPresentationMenuItems([]);
                }
            })
    }

    useEffect(() => {
        fetch("api/countries")
            .then((data) => data.json())
            .then((data) => setCountryList(data))

        fetch("api/brands")
            .then((data) => data.json())
            .then((data) => {
                var dict = {};
                data.forEach((item) => dict[item.brandName] = item);
                setBrandList(dict);
            });

        loadPresentations(null, '');

        var savedSearch = searchParams.get('search');
        if (savedSearch) {
            fetch("api/savedsearch/GetSavedSearch/" + savedSearch)
                .then((data) => data.json())
                .then((data) => {
                    if (data && data.searchFilters) {
                        var savedFilters = JSON.parse(data.searchFilters);
                        if (savedFilters) {
                            setCountry(savedFilters.country || []);
                            if (savedFilters.fromDate) setFromDate(new Date(savedFilters.fromDate * 1000));
                            if (savedFilters.toDate) setToDate(new Date(savedFilters.toDate * 1000));
                            if (savedFilters.query) setQuery(savedFilters.query);

                            if (savedFilters.brand) filters.current['brand.brandName'] = savedFilters.brand;
                            if (savedFilters.mealpart) filters.current['mealpart'] = savedFilters.mealpart;
                            if (savedFilters.daypart) filters.current['daypart'] = savedFilters.daypart;
                        }
                    }
                    setSavedSearchLoaded(true);
                })
                .catch(() => {
                    setSnackbar({ children: 'Failed to load saved search', severity: 'error' });
                    setSavedSearchLoaded(true);
                });
        } else {
            setSavedSearchLoaded(true);
        }
    }, [dispatch])

    

    useEffect(() => {
        if (numberOfItemsSelected < 0) {
            setNumberOfItemsSelected(0);
        }
        setBtnDisabled(!numberOfItemsSelected > 0);
    }, [numberOfItemsSelected]);
    const useStyles = makeStyles(theme => ({
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        menuPaper: {
            maxHeight: 300
        }
    }));
    function saveSearchOpen() {
        setCreateSavedSearchOpen(true);
    }
    function saveSearchClose() {
        setCreateSavedSearchOpen(false);
    }

    function saveSearch() {
        if (savingSearch)
            return;

        var dataFilters = {};
        if (country) dataFilters['country'] = country;
        if (fromDate) dataFilters['fromDate'] = parseInt(fromDate.getTime() / 1000);
        if (toDate) dataFilters['toDate'] = parseInt(toDate.getTime() / 1000);
        if (query) dataFilters['query'] = query;
        if (filters.current['brand.brandName']) dataFilters['brand'] = filters.current['brand.brandName'];
        if (filters.current['mealpart']) dataFilters['mealpart'] = filters.current['mealpart'];
        if (filters.current['daypart']) dataFilters['daypart'] = filters.current['daypart'];

        var data = {
            Staff: user.id,
            SearchFilters: JSON.stringify(dataFilters),
            Keywords: query,
            SearchName: createSavedSearchName.current.value
        };

        setSavingSearch(true);

        dispatch(createSavedSearch(data))
            .then(unwrapResult).then(originalPromiseResult => {
                setCreateSavedSearchOpen(false);
                setSavingSearch(false);
                setSnackbar({ children: 'Search saved successfully', severity: 'success' });
            })
            .catch(rejectedValueOrSerializedError => {
                setCreateSavedSearchOpen(false);
                console.log(rejectedValueOrSerializedError);
                setSavingSearch(false);
                setSnackbar({ children: 'Failed to save search', severity: 'error' });
            });
    }

    function saveCreatePresentation() {
        if (savingCreatePresentation)
            return;

        var data = {
            Staff: user.id,
            PresentationName: createPresentationName.current.value
        };

        setSavingCreatePresentation(true);

        dispatch(createPresentation(data))
            .then(unwrapResult).then(originalPromiseResult => {
                var data = {
                    id: originalPromiseResult.id,
                    menuItems: presentationMenuItems
                };

                dispatch(updatePresentationMenuItems(data)).then(() => {
                    setSnackbar({ children: 'Presentation Saved successfully', severity: 'success' })
                    setNumberOfItemsSelected(0);
                    loadPresentations(null, '');
                }
                    
                )
                    .catch(rejectedValueOrSerializedError => {
                        console.log(rejectedValueOrSerializedError);
                        setSnackbar({ children: 'Failed to save presentation' });
                    });
                setSavingCreatePresentation(false);
                setCreatePresentationOpen(false);
                setSnackbar({ children: 'Presentation created successfully', severity: 'success' });
                setPresentationMenuItems([]);

                //loadPresentations(originalPromiseResult.id, originalPromiseResult.presentationName, user.id);
            })
            .catch(rejectedValueOrSerializedError => {
                console.log(rejectedValueOrSerializedError);
                setSavingCreatePresentation(false);
                setCreatePresentationOpen(false);
                setSnackbar({ children: 'Failed to create presentation', severity: 'error' });
            });
    }

    function dismissCreatePresentation() {
        setCreatePresentationOpen(false);
    }

    

    function togglePresentationMenuItems(ids, add) {
        if (!presentationMenuItems)
            setPresentationMenuItems(ids);
        


        var newList = null;
        if (add) {
            var adds = ids.filter(id => !presentationMenuItems.includes(id));
            if (adds.length > 0) {
                newList = presentationMenuItems.concat(adds);
                setNumberOfItemsSelected(numberOfItemsSelected + adds.length);
            }
        }
        else {
            
            var removes = ids.filter(id => presentationMenuItems.includes(id));
            if (removes.length > 0) {
                newList = presentationMenuItems.filter(id => !removes.includes(id));
                setNumberOfItemsSelected(numberOfItemsSelected - removes.length);
            }
                            
        }

        if (newList) {
            setPresentationMenuItems(newList);
        }
        if (numberOfItemsSelected < 0) {
            setNumberOfItemsSelected(0);
        }
    }

    function BrandRefinementList(props) {
        const { attribute } = props;
        const { fieldLabel, ...extra } = props;
        const { items, refine } = useRefinementList(extra);

        return (
            <FormControl sx={{ m: 1, width: '290px', backgroundColor: '#fff' }} variant="outlined">
                <InputLabel id={attribute + '-label'} size="small">{fieldLabel}</InputLabel>
                <Select
                    labelId={attribute + '-label'}
                    id={attribute}
                    multiple
                    value={items.filter(item => item.isRefined).map(item => item.value)}
                    label={fieldLabel}
                    onChange={(event) => {
                        const {
                            target: { value },
                        } = event;
                        // On autofill we get a stringified value.
                        if (typeof value === 'string')
                            value = value.split(',');

                        items.filter(item => item.isRefined).forEach(item => refine(item.value));
                        if (value) {
                            value.forEach(v => {
                                if (v)
                                    refine(v);
                            });
                        }
                        filters.current[attribute] = value;
                    }}
                    size="small"
                    className={attribute + "-select"}
                    renderValue={(selected) => ((selected.length > 1) ? (selected.length + ' items') : selected[0])}
                >
                    {items.map(item => {
                        let brand = brandList[item.value] || {};
                        return (
                            <MenuItem value={item.value} key={item.value}>
                                <Checkbox sx={{ p: '5px' }} checked={items.filter(item => item.isRefined).map(item => item.value).indexOf(item.value) > -1} />
                                <img className="select-icon" src={brand.brandLogo !== '' && brand.brandLogo !== undefined ? BRANDS_URL_PREFIX +
                                    brand.brandLogo : "/images/blank.png"} />
                                {" "}{item.value} ({item.count})
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        );
    }

    function SimpleRefinementList(props) {
        const { attribute } = props;
        const { fieldLabel, ...extra } = props;
        const { items, refine } = useRefinementList(extra);
        
        return (
            <FormControl sx={{ m: 1, width: '215px', backgroundColor: '#fff' }}>
                <InputLabel id={attribute + '-label'} size="small">{fieldLabel}</InputLabel>
                <Select
                    labelId={attribute + '-label'}
                    id={attribute}
                    multiple
                    value={items.filter(item => item.isRefined).map(item => item.value)}
                    label={fieldLabel}
                    onChange={(event) => {
                        const {
                            target: { value },
                        } = event;
                        // On autofill we get a stringified value.
                        if (typeof value === 'string')
                            value = value.split(',');

                        items.filter(item => item.isRefined).forEach(item => refine(item.value));
                        if (value) {
                            value.forEach(v => {
                                if (v)
                                    refine(v);
                            });
                        }
                        filters.current[attribute] = value;
                    }}
                    size="small"
                    className={attribute + "-select"}
                    renderValue={(selected) => ((selected.length > 1) ? (selected.length + ' items') : selected[0])}
                >
                    {items.map(item => (
                        <MenuItem value={item.value} key={item.value}>
                            <Checkbox sx={{ p: '5px' }} checked={items.filter(item => item.isRefined).map(item => item.value).indexOf(item.value) > -1} />
                            {item.value} ({item.count})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    function CustomClearRefinements(props) {
        const { refine } = useClearRefinements(props);

        return (
            <FormControl sx={{ m: 1, width: '135px' }}>
                <Button
                    variant="contained"
                    // sx={{ mt: 3, ml: 1 }}                               
                    color="primary"
                    disableElevation
                    onClick={() => {
                        filters.current = {};
                        refine();
                        setCountry([]);
                        setFromDate(new Date(2022, 0, 1));
                        setToDate(new Date());
                    }}
                >
                    Clear Filters
                </Button>
            </FormControl>
        );
    }

    function SelectAllHits(props) {
        const { hits, results, sendEvent } = useHits(props);

        var selectAllIds = [];
        var excludedCount = 0;
        if (presentationMenuItems) {
            selectAllIds = hits.map(hit => hit.id);
            excludedCount = selectAllIds.filter(id => !presentationMenuItems.includes(id)).length;
        }

        return (
            <FormControlLabel
                className="checkbox-label"
                control={<Checkbox
                    checked={presentationMenuItems && excludedCount == 0}
                    onChange={(event) => togglePresentationMenuItems(selectAllIds, event.target.checked)}
                />}
                label="Select All For Presentation"
                title={presentationMenuItems ? '' : 'Please select or create a presentation to get started'}
            />
        );
    }

    // Loading...
    if (!(savedSearchLoaded && countryList && brandList && presentationList))
        return null;

    let searchFilters = [];
    let refinementList = {};

    // Country
    if (country) {
        let vs = country.map(v => 'country.countryName:"' + v + '"');
        if (vs.length > 0)
            searchFilters.push('(' + vs.join(' OR ') + ')');
    } 

    // Date range
    var fromDateMonth = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
    var toDateMonth = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 1);
    searchFilters.push('date_timestamp >= ' + (fromDateMonth.getTime() / 1000));
    searchFilters.push('date_timestamp < ' + (toDateMonth.getTime() / 1000));

    // Refinements
    for (var key in filters.current) {
        let r = filters.current[key];
        if (r && r.length > 0)
            refinementList[key] = r;
    }
    
    // UI State
    let initialUiState = {
        [indexName]: {
            refinementList: refinementList,
            page: (pageIndex.current || 0)
        }
    };
    
    return (
        <>
            {createPresentationOpen ? (
                <ClickAwayListener onClickAway={dismissCreatePresentation}>
                    <Popper open={createPresentationOpen} anchorEl={presentationsRef.current} placement='bottom-end' transition >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper sx={{position:'absolute',left:'-40vw',top:'22vh'} }>
                                    <Box sx={{ backgroundColor: '#f7f7f7', display: 'flex', flexDirection: 'column', p: 1,  }}>
                                        <Typography sx={{ p: 2 }}><em>Get Started With Your Presentation</em></Typography>
                                        <FormControl sx={{ m: 1, width: '300px', backgroundColor: '#fff' }} variant="outlined">
                                            <TextField
                                                label="Name Your Presentation"
                                                fullWidth
                                                id="create-presentation-input"
                                                size="small"
                                                placeholder="i.e. McDonald's Deck"
                                                inputRef={createPresentationName}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ m: 1, width: '150px' }}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                loading={savingCreatePresentation}
                                                onClick={saveCreatePresentation}
                                            >
                                                Create Presentation
                                            </LoadingButton>
                                        </FormControl>
                                    </Box>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </ClickAwayListener>
            ) : null}

            {createSavedSearchOpen ? (
                <ClickAwayListener onClickAway={saveSearchClose}>
                    <Popper open={createSavedSearchOpen} anchorEl={presentationsRef.current} placement='bottom-end' transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                    <Box sx={{ backgroundColor: '#f7f7f7', display: 'flex', flexDirection: 'column', p: 1, marginTop: '250px' }}>
                                        <Typography sx={{ p: 2 }}><em>Give your search a name.</em></Typography>
                                        <FormControl sx={{ m: 1, width: '300px', backgroundColor: '#fff' }} variant="outlined">
                                            <TextField
                                                label="Name Your Search"
                                                fullWidth
                                                id="create-presentation-input"
                                                size="small"
                                                defaultValue={query}
                                                required
                                                placeholder="i.e. McDonald's Deck"
                                                inputRef={createSavedSearchName}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ m: 1, width: '150px' }}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                loading={savingCreatePresentation}
                                                onClick={() => {
                                                    if (createSavedSearchName && createSavedSearchName.current.value !== '') {
                                                        saveSearch();
                                                    }

                                                }}
                                            >
                                                Save Search
                                            </LoadingButton>
                                        </FormControl>
                                    </Box>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </ClickAwayListener>
            ) : null}
            
            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexWrap: 'nowrap',
                    mt: 2,
                    mb: 2
                }} fixed>
                <FormControl sx={{ m: 1, width: '270px' }} variant="outlined">
                    <InputLabel id="country-label" size="small">Your Market</InputLabel>
                    <Select
                        labelId="country-label"
                        id="country"
                        multiple
                        value={country}
                        label="Your Market"
                        onChange={(event) => {
                            const {
                                target: { value },
                            } = event;
                            // On autofill we get a stringified value.
                            if (typeof value === 'string')
                                value = value.split(',');

                            filters.current = {};
                            setCountry(value);
                        }}
                        size="small"
                        fullWidth
                        className="country-select"
                        renderValue={(selected) => ((selected.length > 1) ? (selected.length + ' items') : selected[0])}
                    >
                        {countryList.map(countryItem => (
                            <MenuItem value={countryItem.countryLongName} key={countryItem.countryLongName}>
                                <Checkbox sx={{p:'5px'}} checked={country.indexOf(countryItem.countryLongName) > -1} />
                                <img className="select-icon" src={FLAG_URL_PREFIX + countryItem.countryFlagImage} />{" "}
                                {countryItem.countryLongName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}
                            label="Date From"
                            value={fromDate}
                            minDate={new Date('2021-01-02')}
                            maxDate={new Date()}
                            onChange={(newFromDate) => {
                                setFromDate(newFromDate);
                            }}
                            openTo='month'
                            disableCloseOnSelect={false}
                            renderInput={(params) =>
                                <TextField {...params} className="date-picker" helperText={null} name="fromDate" size="small" />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}

                            label="Date To"
                            openTo='month'
                            value={toDate}
                            minDate={new Date('2021-01-02')}
                            maxDate={new Date()}
                            disableCloseOnSelect={false}
                            onChange={(newToDate) => {
                                setToDate(newToDate);
                            }}
                            renderInput={(params) => <TextField {...params} className="date-picker" helperText={null} name="toDate" size="small" />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ m: 1, width: '500px' }} variant="outlined" ref={presentationsRef}>
                    <TextField
                        label="Keyword Search"
                        type="search"
                        fullWidth
                        id="search-input"
                        size="small"
                        placeholder='Word (spicy), phrase ("chicken pizza"), or exclusion (-coffee)'
                        InputLabelProps={{ shrink: true }}
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><SearchIcon className='search-icon' /></InputAdornment>,
                        }}
                    />
                </FormControl>
            </Container>

            <InstantSearch
                searchClient={searchClient}
                indexName={indexName}
                routing={false}
                initialUiState={initialUiState}
                onStateChange={({ uiState, setUiState }) => {
                    // Scroll up
                    setTimeout(() => {
                        if (window.scrollY > scrollUpY)
                            window.scrollTo(0, scrollUpY);
                    }, 1);

                    if (uiState && uiState[indexName])
                        pageIndex.current = uiState[indexName].page || 0;

                    setUiState(uiState);
                }}
            >
                <Configure
                    advancedSyntax="true"
                    query={query}
                    filters={searchFilters.join(' AND ')} />

                <Container maxWidth="xl" sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    mt: 2,
                    mb: 2,
                    backgroundColor: '#f7f7f7',
                    pt: 2,
                    pb: 2,

                }} fixed className="filters-bar">
                    <Box sx={{
                        maxWidth: '850px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                            <em>Refine</em>
                        </Typography>

                        <BrandRefinementList attribute="brand.brandName" operator="or" fieldLabel="Brands" limit="1000" />
                        <SimpleRefinementList attribute="mealpart" fieldLabel="Meal Part" limit="1000" />
                        <SimpleRefinementList attribute="daypart" fieldLabel="Day Part" limit="1000" />

                        <CustomClearRefinements />
                    </Box>
                    <LoadingButton
                        sx={{ m: 1, width: '135px' }}
                        variant="contained"
                        color="primary"
                        disableElevation
                        ref={saveSearchRef}
                        loading={savingSearch}
                        onClick={saveSearchOpen}
                    >
                        Save This Search
                    </LoadingButton>
                </Container>

                <Container
                    sx={{
                        maxWidth: "1340px",
                        display: 'flex',
                        flexWrap: 'nowrap',
                        mt: 2,
                        mb: 2,
                        justifyContent: 'space-between',
                        backgroundColor: '#f7f7f7',
                        pt: 1,
                        pb: 1
                    }} fixed>
                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                        <em>Search Results:</em> <CustomPagination />
                    </Typography>
                    
                </Container>

                <Container
                    sx={{
                        maxWidth: "1340px",
                        display: 'flex',
                        flexWrap: 'nowrap',
                        mt: 2,
                        mb: 2,
                        justifyContent: 'space-between',

                    }} fixed>

                    <SelectAllHits />
                    <Typography variant="body3" sx={{ pr: 1, pl: 1, ml: '10px', alignItems: 'center',width:'30%',alignSelf: 'center', justifyContent: 'start', left: '0px' }} >
                        {(numberOfItemsSelected == 0) ? 'Select the items you want to add to a new or existing presentiation.' : numberOfItemsSelected + ' Items Selected'}
                    </Typography>
                    <Box sx={{
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                        
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            disabled={btnDisabled}
                            ref={newPresentationRef}
                            onClick={handleClick}
                        >
                            + Create a New Presentation
                        </Button>
                        <Typography variant="body2" sx={{ pl:1,pr:1} }>or</Typography>
                        <FormControl sx={{  width: '255px' }} variant="outlined">
                            <TextField
                                select
                                disabled={btnDisabled}
                                id="presentations"
                                value={presentation}
                                label="Select a Presentation"
                                onChange={(event) => loadPresentationMenuItems(event.target.value)}
                                size="small"
                                fullWidth
                                className="select"
                            >
                            
                                {presentationList.map(presentation => (
                                    <MenuItem value={presentation.id} key={presentation.id}>
                                        {presentation.presentationName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        
                    </Box>
                </Container>

                <Container
                    sx={{
                        maxWidth: "1340px",
                        display: 'flex',
                        flexDirection: 'column',
                        mt: 2,
                        mb: 2,
                    }} fixed>
                    <Hits hitComponent={({ hit }) => <MenuItemHit hit={hit} presentationMenuItems={presentationMenuItems} togglePresentationMenuItems={togglePresentationMenuItems}  /> } />
                    <Pagination />
                </Container>

            </InstantSearch>

            {!!snackbar && (
                <Snackbar open onClose={() => setSnackbar(null)} autoHideDuration={5000}>
                    <Alert {...snackbar} onClose={() => setSnackbar(null)} />
                </Snackbar>
            )}
        </>
    );
}
export default BrowseMenuItems