import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import "./MenuItems.css";
import Container from '@mui/material/Container'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Snackbar from '@mui/material/Snackbar';
import { format } from 'date-fns'
import { selectUserPresentations, fetchUserPresentations, fetchPresentationItems, updatePresentationMenuItems, addMenuItems, updatePresentationMenuItemOrder } from '../store/presentationsSlice'
import { BRANDS_URL_PREFIX, FLAG_URL_PREFIX, SCREENGRAB_URL_PREFIX, MENU_URL_PREFIX } from '../components/shared/Constants'
import { useGridSelector } from '@mui/x-data-grid-pro';
import Modal from '@mui/material/Modal';
import { func } from 'prop-types';
import { getUser, getUserStatus, getUserError, fetchAuthUser } from '../store/userSlice'
import { arrayMoveImmutable as arrayMove } from 'array-move';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Alert from '@mui/material/Alert';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Container as DraggableContainer, Draggable } from "@edorivai/react-smooth-dnd";

export const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
};



const Presentations = () => {
    const now = new Date();
    const [fromDate, setFromDate] = React.useState(new Date(2022, 5, 1));
    const [toDate, setToDate] = React.useState(new Date(now.getFullYear(), now.getMonth(), 1));
    const [keyword, setKeyword] = React.useState('');
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const [data, setData] = React.useState([]);
    const [presName, setPresName] = React.useState('');
    const [snackbar, setSnackbar] = React.useState(null);
    



    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const presentations = useSelector(selectUserPresentations)



    const onDrop = ({ removedIndex, addedIndex }) => {
        console.log({ removedIndex, addedIndex });
        setData(data => arrayMove(data, removedIndex, addedIndex));
    };

    useEffect(() => {
        dispatch(fetchUserPresentations({ data: { staff: user.id, fromDate: fromDate, toDate: toDate, keyword: keyword } }))
    }, [keyword, fromDate, toDate, dispatch])

    function editPresentation(id) {
        var items = dispatch(fetchPresentationItems({ id: id })).then(originalPromiseResult => {
            setData(originalPromiseResult.payload.presentationMenuItem);
            setPresName(originalPromiseResult.payload.presentationName);
            handleOpen(true);

        });
    }

    const valueRef = useRef(false)

    const sendValue = () => {
        return console.log(valueRef.current.value) //on clicking button accesing current value of TextField and outputing it to console 
    }

    const [values, setValues] = React.useState({
    });




    function saveOrder() {
        console.log(data);
        dispatch(updatePresentationMenuItemOrder({ data,presName })).then(() => {
            
            dispatch(fetchUserPresentations({ data: { staff: user.id, fromDate: fromDate, toDate: toDate, keyword: keyword } })).then(() => {
                setSnackbar({ children: 'Presentation Saved successfully', severity: 'success' });
                handleClose(true);
            });
            
        }

        );
        
    }
    
    
    const style = {
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%,-50%)',
        width: 550,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        maxHeight: '80%',
        overflowY: 'scroll',
        overflowX:'none',
        p: 2,
        pr:1

    };
    const [presentationsToExport, setPresentationsToExport] = useState([]);


    return (

        <>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                
                <Box sx={style}>
                    <Typography>
                        Edit <TextField                            
                            fullWidth
                            id="search-input"
                            size="small"
                            placeholder={presName}
                            InputLabelProps={{ shrink: true }}
                            value={presName}
                            onChange={event => {
                                const { value } = event.target;
                                setPresName(value);
                            }}
                        />
                    </Typography>
                    <Typography
                        variant="body2">
                        Drag items to reorder presentation. Delete unwanted items.
                    </Typography>

                    <List >
                        <div className="simple-page-scroller">
                            <DraggableContainer  dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                                {data.map((item, index) => (
                                    <Draggable sx={{ bgcolor: '#dbdbdb' }} key={item.id}>
                                        <ListItem >
                                            <ListItemIcon className="drag-handle">
                                                <DragHandleIcon />
                                            </ListItemIcon>

                                            <Typography
                                                variant="body2"
                                                sx={{mr:3} }
                                            >
                                                {item.menuItem.menuItemName}
                                                </Typography>
                                            
                                            <ListItemSecondaryAction mx={{alignItems:'right'} }>
                                                <Button sx={{ml:2,alignItems:'right'}} onClick={() => { setData(data.filter((_, i) => i !== index)) }} >
                                                    <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </Draggable>
                                ))}
                            </DraggableContainer>
                            </div>
                        </List>
                    <CardActions>
                        <Button variant="contained" color="success" 
                            onClick={() => {
                                saveOrder()
                            }}
                        >Save</Button>
                    </CardActions>
                </Box>

            </Modal>
            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexWrap: 'nowrap',
                    mt: 2,
                    mb: 2
                }} fixed>

                <FormControl sx={{ m: 1 }} variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}
                            label="Date From"
                            value={fromDate}
                            minDate={new Date('2021-01-02')}
                            maxDate={new Date()}
                            onChange={(newFromDate) => {
                                setFromDate(newFromDate);
                            }}
                            openTo='month'
                            disableCloseOnSelect={false}
                            renderInput={(params) =>
                                <TextField {...params} className="date-picker" helperText={null} name="fromDate" size="small" />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={['year', 'month']}

                            label="Date To"
                            openTo='month'
                            value={toDate}
                            minDate={new Date('2021-01-02')}
                            maxDate={new Date(Date.now())}
                            disableCloseOnSelect={false}
                            onChange={(newToDate) => {
                                setToDate(newToDate);
                            }}
                            renderInput={(params) => <TextField {...params} className="date-picker" helperText={null} name="toDate" size="small" />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ m: 1, width: '500px' }} variant="outlined">
                    <TextField
                        label="Keyword Search"
                        type="search"
                        fullWidth
                        id="search-input"
                        size="small"
                        placeholder='Search through your presentations'
                        InputLabelProps={{ shrink: true }}
                        value={keyword}
                        onChange={event => {
                            const { value } = event.target;
                            setKeyword(value);
                        }}
                        renderInput={(params) => <TextField {...params} className="text" helperText={'Search through your presentations'} name="keyword" size="small" />}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><SearchIcon className='search-icon' /></InputAdornment>,
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '135px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Search
                    </Button>
                </FormControl>

            </Container>

            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexWrap: 'nowrap',
                    mt: 2,
                    mb: 2,
                    justifyContent: 'space-between',
                    backgroundColor: '#f7f7f7',
                    pt: 1,
                    pb: 1
                }} fixed>
                <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                    <em>My Presentations:</em>
                </Typography>

                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}>
                    <DeleteIcon fontSize="small" sx={{ mr: 1 }} />     {"  "}
                    <Button variant="body2"
                        onClick={() => {
                            try {
                                const response = axios.post('api/Presentations/DeletePresentation', presentationsToExport, {
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json;charset=UTF-8",
                                    },
                                })
                                    .then(function (results) {
                                        dispatch(fetchUserPresentations({ data: { staff: user.id, fromDate: fromDate, toDate: toDate, keyword: keyword } }))
                                    })
                                   
                                return [...response.data];
                            } catch (err) {
                                return err.message;
                            }
                        }}
                    >
                        {' Delete Selected'}
                    </Button>
                </Box>

            </Container>

            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexWrap: 'nowrap',
                    mt: 2,
                    mb: 2,
                    justifyContent: 'space-between',
                }} fixed>

            </Container>

            <Container
                sx={{
                    maxWidth: "1340px",
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 2,
                    mb: 2,
                }} fixed>

                {presentations?.map((presentation) => {
                    return (

                        <Box id={presentation.presentation.id} sx={{
                            alignItems: 'center',
                            display: 'flex',
                            borderTop: '2px solid #afafaf',
                            pt: 2,
                            pb: 2,
                        }} className="menu-item-row" key={presentation.presentation.id}>

                            <FormControlLabel className="checkbox-label" sx={{ mr: 0 }}
                                control={
                                    <Checkbox />}
                                label=""
                                checked={valueRef.checked}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setPresentationsToExport([
                                            ...presentationsToExport,
                                            {
                                                id: presentation.presentation.id
                                            },
                                        ]);
                                    } else {
                                        setPresentationsToExport(
                                            presentationsToExport.filter(present => present.id !== presentation.presentation.id),
                                        );
                                    }
                                }}
                                aria-describedby={presentation.presentation.id}
                                inputRef={valueRef}
                            />
                            <Box className="menu-item-row--details"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '90%',
                                    ml: 3
                                }}>
                                <Typography gutterBottom variant="h4" component="div" sx={{ mt: 1, mr: 1, mb: 1 }}>
                                    <em>{presentation.presentation.presentationName}</em>


                                </Typography>
                                <Box sx={{}}>
                                    <Box component="div" sx={{ display: 'inline-flex' }} >
                                        <Button variant="contained" color="success" onClick={() => {
                                            window.location.href = `/api/Presentations/ExportPresentation/export/${presentation.presentation.id}/pptx`
                                        }}>Download as PowerPoint</Button>
                                    </Box>
                                    <Box sx={{ display: 'inline-flex', ml: 3 }}>
                                        <Button variant="contained" color="success" onClick={() => {
                                            window.location.href = `/api/Presentations/ExportPresentation/export/${presentation.presentation.id}/pdf`
                                        }}>Download as PDF</Button>
                                    </Box>
                                    <Box sx={{ display: 'inline-flex', ml: 3 }}>
                                        <Button variant="contained" sx={{ border:1 }} color="neutral" onClick={() => {
                                            editPresentation(presentation.presentation.id);
                                        }}>Edit Presentation</Button>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flexDirection: 'column',
                                    display: 'flex'
                                }}>
                                    <Typography gutterBottom variant="body2" component="div" sx={{ mt: 1, mr: 1 }}>
                                        {presentation.menuItemCount} items in Presentation | Last updated on {format(new Date(presentation.presentation.lastUpdated), 'MMMM d, yyyy, h:mm aa')}
                                    </Typography>


                                </Box>
                            </Box>

                        </Box>

                    )
                })}
            </Container>
            {!!snackbar && (
                <Snackbar open onClose={() => setSnackbar(null)} autoHideDuration={5000}>
                    <Alert {...snackbar} onClose={() => setSnackbar(null)} />
                </Snackbar>
            )}
        </>
    );
}
export default Presentations